import { findFirstNonEmptyString, theme, useResponsive } from "@product/scmp-sdk";
import { useScroll } from "ahooks";
import { useAtomValue, useSetAtom } from "jotai";
import { Fragment, useMemo } from "react";

import { section as sectionData, tracking } from "shared/data";

import { BaseLink, type Props as BaseLinkProps } from "scmp-app/components/common/base-link";
import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { hamburgerMenuAtom } from "scmp-app/components/hamburger-menu/atoms";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { SearchEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { useEditionValue } from "scmp-app/lib/edition";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import { useCurrentPageType, useGetHomeAsPath } from "scmp-app/lib/router/hooks";
import type { OptionalBreakpointKey, RequiredBreakpointKey } from "scmp-app/lib/styles/responsive";
import { page as HomePage } from "scmp-app/pages/home";
import { page as SearchPage } from "scmp-app/pages/search";
import { page as SectionPage } from "scmp-app/pages/section";
import { Variant } from "scmp-app/pages/section/enums";

import { Breakpoints, HeaderArticleSpeechContainerElementID } from "./consts";
import { DynamicHeaderAvatar } from "./dynamics";
import { HeaderContentBookmark } from "./header-content-bookmark";
import { HeaderContentCommentTrigger } from "./header-content-comment-trigger";
import { HeaderContentShareIcon } from "./header-content-share-icon";
import IconMenu from "./header-icon/menu.svg";
import { HeaderMyNews } from "./header-my-news";
import type { ActionButtonProps, StyledLogoLinkProps } from "./styles";
import {
  ActionButton,
  ArticleSpeechActionButton,
  AvatarContainer,
  BookmarkActionButton,
  HeaderArticleSpeechContainer,
  IconMenuContainer,
  IconMenuPlaceholderContainer,
  MobileStyledLogoLink,
  StyledDynamicHeaderOnelineMenuContainer,
  StyledDynamicSubscriptionButton,
  StyledIconSearch,
  StyledLogoLink,
  StyledLogoWithText,
  StyledScmpLogoFlag,
  StyledScmpStyleLogoFlag,
  StyledStyleLogoText,
  SubscriptionActionButton,
} from "./styles";
import {
  type Feature,
  type HeaderConfiguration,
  type PositionVariant,
  PositionVariants,
  type PositionVariantToFeatureConfiguration,
  type ResponsiveFeature,
} from "./types";

export const useSwapOnScrollEffect = (threshold: number) => {
  const { top } = useScroll() ?? { top: 0 };
  return { shouldSwap: top >= threshold };
};

export const useHeader = (shouldSwap: boolean, headerConfiguration?: HeaderConfiguration) => {
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const isScmpSubscriber = asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false;

  // If not provided, use default responsive features
  const responsiveFeatures = useMemo<ResponsiveFeature>(
    () => ({
      desktopUp: {
        default: {
          left: ["logo-link", "search", "my-news"],
          right: ["subscription", "dashboard"],
        },
      },
      largeDesktopUp: {
        default: {
          left: ["logo-link", "search", "my-news"],
          right: ["subscription", "dashboard"],
        },
      },
      mediumDesktopUp: {
        default: {
          left: ["logo-link", "search", "my-news"],
          right: ["subscription", "dashboard"],
        },
      },
      mobileUp: {
        default: { left: ["hamburger-menu"], right: ["dashboard"] },
        nonSubscriberSwapped: { left: ["hamburger-menu", "logo-link"], right: ["dashboard"] },
        swapped: { left: ["hamburger-menu", "logo-link"], right: ["dashboard"] },
      },
      tabletUp: {
        default: {
          left: ["hamburger-menu", "logo-link", "my-news"],
          right: ["subscription", "dashboard"],
        },
      },
      ...headerConfiguration?.responsiveFeatures,
    }),
    [headerConfiguration?.responsiveFeatures],
  );

  const { featureConfigurations, featureList } = useMemo(() => {
    const computeFeatureListForBreakpoint = (
      breakpoint: OptionalBreakpointKey | RequiredBreakpointKey,
      currentPosition: PositionVariant,
    ) => {
      const featureForBreakpoint = responsiveFeatures[breakpoint];
      if (!featureForBreakpoint) return [];

      if (shouldSwap) {
        const swappedFeatures = isScmpSubscriber
          ? featureForBreakpoint.swapped?.[currentPosition]
          : featureForBreakpoint.nonSubscriberSwapped?.[currentPosition];
        if (swappedFeatures) return swappedFeatures;
      }
      return featureForBreakpoint.default[currentPosition];
    };

    const computePositionsVariantToFeatureConfiguration = (
      feature: Feature,
    ): PositionVariantToFeatureConfiguration =>
      PositionVariants.reduce((accumulator, currentPosition) => {
        const responsiveVariants = Breakpoints.reduce(
          (mapping, breakpoint) => ({
            ...mapping,
            [breakpoint]: computeFeatureListForBreakpoint(breakpoint, currentPosition).includes(
              feature,
            )
              ? "show"
              : "hidden",
          }),
          {} as ActionButtonProps["$responsiveVariants"],
        );
        return {
          ...accumulator,
          [currentPosition]: {
            isEnabled: Object.values(responsiveVariants).includes("show"),
            responsiveVariants,
          },
        };
      }, {} as PositionVariantToFeatureConfiguration);

    const featureList = PositionVariants.reduce(
      (accumulator, currentPosition) => ({
        ...accumulator,
        [currentPosition]: Breakpoints.reduce(
          (mapping, breakpoint) => ({
            ...mapping,
            [breakpoint]: computeFeatureListForBreakpoint(breakpoint, currentPosition),
          }),
          {} as Record<OptionalBreakpointKey | RequiredBreakpointKey, Feature[]>,
        ),
      }),
      {} as Record<
        PositionVariant,
        Record<OptionalBreakpointKey | RequiredBreakpointKey, Feature[]>
      >,
    );

    const featureConfigurations = {
      articleSpeech: computePositionsVariantToFeatureConfiguration("article-speech"),
      contentBookmark: computePositionsVariantToFeatureConfiguration("content-bookmark"),
      contentComment: computePositionsVariantToFeatureConfiguration("content-comment"),
      contentShare: computePositionsVariantToFeatureConfiguration("content-share"),
      dashboard: computePositionsVariantToFeatureConfiguration("dashboard"),
      hamburgerMenu: computePositionsVariantToFeatureConfiguration("hamburger-menu"),
      logoLink: computePositionsVariantToFeatureConfiguration("logo-link"),
      logoLinkStyle: computePositionsVariantToFeatureConfiguration("logo-link-style"),
      myNews: computePositionsVariantToFeatureConfiguration("my-news"),
      search: computePositionsVariantToFeatureConfiguration("search"),
      subscription: computePositionsVariantToFeatureConfiguration("subscription"),
      textLinkStyle: computePositionsVariantToFeatureConfiguration("text-link-style"),
    };
    return { featureConfigurations, featureList };
  }, [isScmpSubscriber, responsiveFeatures, shouldSwap]);

  const currentPageType = useCurrentPageType();
  const edition = useEditionValue();
  const homeAsPath = useGetHomeAsPath();
  const isMagazineStyle = headerConfiguration?.appBarVariant === "scmp/magazines-style";
  const setHamburgerMenuState = useSetAtom(hamburgerMenuAtom);

  const mastheadQueryParameter = useMemo(
    () => ({
      module: tracking.module.Masthead,
      pgtype: currentPageType,
    }),
    [currentPageType],
  );

  const styleLogoLinkProps = useMemo<BaseLinkProps & StyledLogoLinkProps>(() => {
    const { pathname: styleSectionPagePathname, query: styleSectionPageQuery } = SectionPage.route({
      appBarVariant: headerConfiguration?.appBarVariant,
      entityId: sectionData.style.entityId,
      sectionVariant: Variant.Style,
      slide: "main",
    });
    return {
      $shouldSwap: shouldSwap,
      anchorProps: { "aria-label": "SCMP style section" },
      as: sectionData.style.urlAlias,
      pathname: styleSectionPagePathname,
      query: { ...styleSectionPageQuery, ...mastheadQueryParameter },
    };
  }, [headerConfiguration?.appBarVariant, mastheadQueryParameter, shouldSwap]);

  const homepageLogoLinkProps = useMemo<BaseLinkProps & StyledLogoLinkProps>(() => {
    const { pathname: homePagePathname, query: homePageQuery } = HomePage.route({
      edition,
      slide: "main",
    });
    return {
      $shouldSwap: shouldSwap,
      anchorProps: { "aria-label": "SCMP homepage" },
      as: homeAsPath,
      pathname: homePagePathname,
      query: { ...homePageQuery, ...mastheadQueryParameter },
    };
  }, [edition, homeAsPath, mastheadQueryParameter, shouldSwap]);

  const handleSearchClick = () => {
    // GA4 21.1.1
    sendGA4Tracking({
      action: "click",
      category: "search",
      subcategory: "icon",
    } as SearchEvent);
  };

  const isMobileUp = useResponsive(theme.breakpoints.up("mobile"), false);
  const isTabletUp = useResponsive(theme.breakpoints.up("tablet"), false);
  const isDesktopUp = useResponsive(theme.breakpoints.up("desktop"), false);
  const isMediumDesktopUp = useResponsive(theme.breakpoints.up("mediumDesktop"), false);
  const isLargeDesktopUp = useResponsive(theme.breakpoints.up("largeDesktop"), false);

  const currentBreakpoint = useMemo(
    () =>
      findFirstNonEmptyString(
        isLargeDesktopUp ? "largeDesktopUp" : undefined,
        isMediumDesktopUp ? "mediumDesktopUp" : undefined,
        isDesktopUp ? "desktopUp" : undefined,
        isTabletUp ? "tabletUp" : undefined,
        isMobileUp ? "mobileUp" : undefined,
      ) as RequiredBreakpointKey,
    [isDesktopUp, isLargeDesktopUp, isMediumDesktopUp, isMobileUp, isTabletUp],
  );

  const { left, right } = useMemo(() => {
    if (!featureConfigurations) return { left: <></>, right: <></> };

    const {
      articleSpeech,
      contentBookmark,
      contentComment,
      contentShare,
      dashboard,
      hamburgerMenu,
      logoLink,
      logoLinkStyle,
      myNews,
      search,
      subscription,
      textLinkStyle,
    } = featureConfigurations;

    const featureRenderFunctions: Partial<
      Record<Feature, (position: PositionVariant) => JSX.Element | null>
    > = {
      "content-bookmark": position =>
        contentBookmark[position].isEnabled ? (
          <BookmarkActionButton $responsiveVariants={contentBookmark[position].responsiveVariants}>
            <HeaderContentBookmark />
          </BookmarkActionButton>
        ) : null,
      "content-comment": position =>
        contentComment[position].isEnabled ? (
          <ActionButton $responsiveVariants={contentComment[position].responsiveVariants}>
            <HeaderContentCommentTrigger />
          </ActionButton>
        ) : null,
      "content-share": position =>
        contentShare[position].isEnabled ? (
          <ActionButton $responsiveVariants={contentShare[position].responsiveVariants}>
            <HeaderContentShareIcon />
          </ActionButton>
        ) : null,
      dashboard: position =>
        dashboard[position].isEnabled ? (
          <ActionButton $responsiveVariants={dashboard[position].responsiveVariants}>
            <AvatarContainer>
              <IdleQueue>
                <DynamicHeaderAvatar />
              </IdleQueue>
            </AvatarContainer>
          </ActionButton>
        ) : null,
      "hamburger-menu": position =>
        hamburgerMenu[position].isEnabled ? (
          <IconMenuPlaceholderContainer
            $responsiveVariants={hamburgerMenu[position].responsiveVariants}
          >
            <IdleQueue>
              <ActionButton
                $responsiveVariants={hamburgerMenu[position].responsiveVariants}
                onClick={() => {
                  setHamburgerMenuState(current => ({
                    ...current,
                    isOpen: !current.isOpen,
                  }));
                  sendGA4Tracking({
                    action: "click",
                    category: "menu",
                    customized_parameters: {
                      action_type: "open",
                      edition,
                      menu_type: "hamburger",
                    },
                    subcategory: "section",
                  });
                }}
              >
                <IconMenuContainer>
                  <IconMenu />
                </IconMenuContainer>
              </ActionButton>
            </IdleQueue>
          </IconMenuPlaceholderContainer>
        ) : null,
      "logo-link": position =>
        logoLink[position].isEnabled ? (
          <StyledLogoLink {...homepageLogoLinkProps}>
            <StyledScmpLogoFlag />
          </StyledLogoLink>
        ) : null,
      "logo-link-style": position =>
        logoLinkStyle[position].isEnabled ? (
          <StyledLogoLink {...homepageLogoLinkProps}>
            <StyledScmpStyleLogoFlag />
          </StyledLogoLink>
        ) : null,
      "my-news": position =>
        myNews[position].isEnabled ? (
          <ActionButton $responsiveVariants={myNews[position].responsiveVariants}>
            <BaseLink
              anchorProps={{ "aria-label": "my news" }}
              as="/mynews"
              pathname="mynews"
              query={mastheadQueryParameter}
            >
              <HeaderMyNews />
            </BaseLink>
          </ActionButton>
        ) : null,
      search: position => {
        const { pathname: searchPathname, query: searchQuery } = SearchPage.route({});
        return search[position].isEnabled ? (
          <ActionButton $responsiveVariants={search[position].responsiveVariants}>
            <BaseLink
              anchorProps={{ "aria-label": "search" }}
              as="/search"
              onClick={handleSearchClick}
              pathname={searchPathname}
              query={{ ...searchQuery, ...mastheadQueryParameter }}
            >
              <StyledIconSearch />
            </BaseLink>
          </ActionButton>
        ) : null;
      },
      subscription: position =>
        subscription[position].isEnabled ? (
          <SubscriptionActionButton $responsiveVariants={subscription[position].responsiveVariants}>
            <IdleQueue>
              <StyledDynamicSubscriptionButton
                variant={
                  headerConfiguration?.appBarVariant === "scmp/magazines-style"
                    ? "dark"
                    : "light-blue"
                }
              />
            </IdleQueue>
          </SubscriptionActionButton>
        ) : null,
      "text-link-style": position =>
        textLinkStyle[position].isEnabled ? (
          <StyledLogoLink {...styleLogoLinkProps}>
            <StyledStyleLogoText />
          </StyledLogoLink>
        ) : null,
    };

    return PositionVariants.reduce(
      (mapping, position) => ({
        ...mapping,
        [position]: (
          <>
            {/* Intentionally not to check `articleSpeech[position].isEnabled` to condition render
              because it will have race condition if the portal container is not exist */}
            {position === "right" && (
              <ArticleSpeechActionButton
                $responsiveVariants={articleSpeech[position].responsiveVariants}
              >
                <HeaderArticleSpeechContainer id={HeaderArticleSpeechContainerElementID} />
              </ArticleSpeechActionButton>
            )}
            {featureList?.[position]?.[currentBreakpoint]?.map(feature => (
              <Fragment key={feature}>{featureRenderFunctions?.[feature]?.(position)}</Fragment>
            ))}
          </>
        ),
      }),
      {} as Record<PositionVariant, JSX.Element>,
    );
  }, [
    currentBreakpoint,
    edition,
    featureConfigurations,
    featureList,
    headerConfiguration?.appBarVariant,
    homepageLogoLinkProps,
    mastheadQueryParameter,
    setHamburgerMenuState,
    styleLogoLinkProps,
  ]);

  const center = useMemo(
    () => (
      <>
        {!isMagazineStyle && !shouldSwap && (
          <MobileStyledLogoLink {...homepageLogoLinkProps}>
            <StyledLogoWithText />
          </MobileStyledLogoLink>
        )}

        <StyledDynamicHeaderOnelineMenuContainer
          appBarVariant={headerConfiguration?.appBarVariant}
        />
      </>
    ),
    [headerConfiguration?.appBarVariant, homepageLogoLinkProps, isMagazineStyle, shouldSwap],
  );

  return {
    featureConfigurations,
    features: {
      center,
      left,
      right,
    },
    homepageLogoLinkProps,
    mastheadQueryParameter,
    styleLogoLinkProps,
  };
};
